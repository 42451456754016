import React, { useState } from "react";
import { motion } from "framer-motion";

export default function Prompt({ text, isVisible }) {
  const [key, setKey] = useState(0);

  const variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 500,
      },
    },
    hidden: {
      opacity: 0,
      y: 30,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 500,
      },
    },
  };

  return (
    <motion.div
      key={key}
      className="unselectable"
      onClick={() => setKey(key + 1)}
      style={{ display: "flex", overflow: "hidden" }}
      variants={variants}
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.5 }}
    >
      {text}
    </motion.div>
  );
}
