export function emojiByCategory(item) {
  const categoryEmoji = {
    "Movies & TV": "🎥",
    Movie: "🎥",
    "TV Show": "📺",
    "Songs & Artists": "🎵",
    "Musical Artist": "🎵",
    Song: "🎵",
    Book: "📚",
    Brand: "🛍️",
    Sports: "🏀",
    Athlete: "👟",
    Quote: "💬",
    Phrase: "💬",
    Food: "🍔",
    Travel: "✈️",
    History: "📜",
    Science: "🧪",
    Nature: "🌳",
    Art: "🎨",
    Game: "🎮",
    Miscellaneous: "🤷‍♂️",
  };
  return categoryEmoji[item];
}
