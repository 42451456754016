const dummyData = [];

for (let i = 112; i < 151 && i > 0; i--) {
  const todaysDate = new Date();

  todaysDate.setDate(todaysDate.getDate() - i);

  const formattedDate = `${todaysDate.getFullYear()}-${(
    "0" +
    (todaysDate.getMonth() + 1)
  ).slice(-2)}-${("0" + todaysDate.getDate()).slice(-2)}`;

  dummyData.push({ count: 0, date: formattedDate, level: 0 });
}

export { dummyData };
