export function emojiByScore(score) {
  const scoreEmoji = {
    5: "🥇",
    4: "🥈",
    3: "🥉",
    2: "🥔",
    1: "🪨",
    0: "💩",
  };
  return scoreEmoji[score];
}
