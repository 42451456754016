import React from "react";
import { Text, List } from "@mantine/core";

export default function HowToPlayText() {
  return (
    <>
      <Text size="sm">
        <b>
          1. <u>Sound out the emojis</u>
        </b>{" "}
        to form the answer!
        <div style={{ height: "10px" }}></div>
        <List size="sm">
          <List.Item>
            <u>Full Words</u>
            <br></br>
            🌎📅 = "Earth Day"<br></br>
            🌎🦍🦍 = "Planet of the Apes"<br></br>
          </List.Item>
          <div style={{ height: "10px" }}></div>
          <List.Item>
            <u>Syllables</u>
            <br></br>
            🅱️🌎📅 = "B-<i>irth</i>-day"<br></br>
            🥧🐀 = "<i>Pi</i>-<i>rat(e)</i>"
          </List.Item>
        </List>
        <div style={{ height: "10px" }}></div>
        <List size="sm">
          <b>
            2. <u>Use hints as context clues</u>
          </b>{" "}
          - think characters, quotes, motifs, & themes. In some cases, you may
          still need to sound them out!
        </List>
        <div style={{ height: "10px" }}></div>
        <List size="sm">
          <b>More Examples</b>
          <div style={{ height: "2px" }}></div>
          🥧🐀🚗🍖☕ = Pi-rat(s)-(of the)-Car-rib-bean<br></br>
          <u>Hints</u>: 💲🍐🛶 = S-pear-row (Sparrow, as in <i>Captain</i> Jack
          Sparrow), 🏴‍☠️ = a thematic clue
          <div style={{ height: "10px" }}></div>
          🍽️🎮 = The Hunger - Games
          <br></br>
          <u>Hints</u>: 🕊️🔥 = Mockingjay, 👩‍🦰🏹 = Katniss Everdeen
          <br></br>
          {/* <List.Item>
            <u>Prompt</u>: <span style={{ fontSize: "16px"}}>⛵️⛵️⛵️🪵💻</span> = Fleet - Wood - Mac<br></br>
            <u>Hints</u>: ⛓️ = "The Chain", 🛬🎚️ = "Land-Slide" (songs by the artist)
            </List.Item>

          <br></br> */}
        </List>
      </Text>
    </>
  );
}
