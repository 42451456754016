import React, { useEffect } from "react";
import { useReward } from "react-rewards";

export default function EmojiBurst() {
  const { reward: emojiReward, isAnimating: isEmojiAnimating } = useReward(
    "emojiReward",
    "emoji",
    {
      emoji: ["😭", "😡", "😤", "🤬", "🤧", "😢"],
      angle: 90,
      elementCount: 50,
      startVelocity: 35,
      decay: 0.94,
      lifetime: 200,
      zIndex: 1000,
    }
  );

  useEffect(() => {
    emojiReward();
  }, []);

  return (
    <div className="centered">
      <span id="emojiReward" />
    </div>
  );
}
