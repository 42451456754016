import React, { useEffect } from "react";
import { useReward } from "react-rewards";

export default function HighFiveBurst() {
  const { reward: highFiveReward, isAnimating: isEmojiAnimating } = useReward(
    "highFiveReward",
    "emoji",
    {
      emoji: "✋",
      angle: 90,
      elementCount: 5,
      startVelocity: 15,
      decay: 0.96,
      lifetime: 200,
      zIndex: 1000,
    }
  );

  useEffect(() => {
    highFiveReward();
  }, []);

  return (
    <div className="centered">
      <span id="highFiveReward" />
    </div>
  );
}
