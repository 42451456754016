import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Share } from "react-native";
import { emojiByCategory } from "./utils/iconHelper";
// import InputMask from "react-input-mask";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import Prompt from "./components/Prompt";
import Plausible from "plausible-tracker";
import {
  Accordion,
  AppShell,
  Badge,
  Box,
  Burger,
  Button,
  Card,
  Center,
  Checkbox,
  Code,
  Container,
  Group,
  Header,
  Image,
  // Input,
  List,
  Loader,
  LoadingOverlay,
  MediaQuery,
  Modal,
  NativeSelect,
  Navbar,
  Popover,
  ScrollArea,
  // SegmentedControl,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";

import {
  NotificationsProvider,
  showNotification,
} from "@mantine/notifications";
import ConfettiBurst from "./components/ConfettiBurst";
import { emojiByScore } from "./utils/scoreHelper";
import EmojiBurst from "./components/EmojiBurst";
import useLocalStorage from "./hooks/useLocalStorage";
import HighFiveBurst from "./components/HighFiveBurst";
import RebusPopover from "./components/RebusPopover";
import { CountDown } from "./components/CountDown";
import messageByScore from "./utils/messageByScore";
import ECActivityCalendar from "./components/ECActivityCalendar";
import FacebookButton from "./components/FacebookButton";
import iconByNumber from "./utils/iconByNumber";
import { dummyData } from "./utils/dummyDataHelper";
// import LogRocket from "logrocket";
import HowToPlay from "./components/HowToPlay";
import HowToPlayText from "./components/HowToPlayText";
import PrivacyPolicy from "./components/PrivacyPolicy";

// LogRocket.init("djxraj/emojiclue-prod");
// eslint-disable-next-line
const plausible = Plausible({ domain: "emojiclue.com" });

// const { trackPageview } = Plausible();

const { trackEvent } = Plausible();

const baseUrl =
  process.env.NODE_ENV === "production"
    ? "https://api.emojiclue.com"
    : "http://localhost:8081";

const headerEmojiArray = ["🤔", "😄", "😜", "😝", "🌝", "😎"];
const guessLettersArray = [];
const archiveGuessLettersArray = [];
const contributionEmojiArray = [];

//Client time zone calculation
const todaysDate = new Date();
const msTimeZoneOffset = 1000 * 60 * todaysDate.getTimezoneOffset();

//Remove time element from ISO date by splitting & destructuring
const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds

// const [todaysDateStats] = new Date(Date.now() - tzoffset)
//   .toISOString()
//   .split("T");

// console.log(todaysDateStats);
const todaysDateStats = "2022-12-05";

//Calculate the hours, minutes, and seconds until midnight

//Copyright year
const currentYear = new Date().getFullYear();

export default function App() {
  const hasTouchRef = useRef(false);
  const isTouch = "ontouchstart" in window || navigator.msMaxTouchPoints;
  const guessRef = useRef();
  const contributionEmojiRef = useRef();
  const contributionAnswerRef = useRef();
  const [navbarOpened, setNavbarOpened] = useState(false);
  const [todaysChallenge, setTodaysChallenge] = useState("");
  console.log(todaysChallenge);
  const [accordionValueOpen, setAccordionValueOpen] = useState("");
  const [completeModalOpened, setCompleteModalOpened] = useLocalStorage(
    "completeModalOpened",
    false
  );
  const [hint1ModalOpened, setHint1ModalOpened] = useState(false);
  const [hint2ModalOpened, setHint2ModalOpened] = useState(false);

  const [hintRevealedCount, setHintRevealedCount] = useLocalStorage(
    "hintRevealedCount",
    0
  );
  const [welcomeModalOpened, setWelcomeModalOpened] = useLocalStorage(
    "welcomeModalOpened",
    false
  );
  const [howToPlayModalOpened, setHowToPlayModalOpened] = useLocalStorage(
    "howToPlayModalOpened",
    true
  );
  const [howToHint1ModalOpened, setHowToHint1ModalOpened] = useLocalStorage(
    "howToHint1ModalOpened",
    false
  );
  const [howToHint2ModalOpened, setHowToHint2ModalOpened] = useLocalStorage(
    "howToHint2ModalOpened",
    false
  );
  const [isFirstHint, setIsFirstHint] = useLocalStorage("isFirstHint", true);
  const [hint1Shown, setHint1Shown] = useLocalStorage("hint1Shown", false);
  const [hint2Shown, setHint2Shown] = useLocalStorage("hint2Shown", false);
  const [headerEmoji, setHeaderEmoji] = useState("🤔");
  const [highFiveCount, setHighFiveCount] = useLocalStorage(
    "highFiveCount",
    394
  );
  const [scoreData, setScoreData] = useLocalStorage("scoreData", dummyData);
  const [headerEmojiClickCount, setHeaderEmojiClickCount] = useState(1);
  const [guess, setGuess] = useState("");

  const [guessCount, setGuessCount] = useLocalStorage("guessCount", 0);
  const [prevGuesses, setPrevGuesses] = useLocalStorage("prevGuesses", []);
  const [isComplete, setIsComplete] = useLocalStorage("isComplete", false);
  const [isCorrect, setIsCorrect] = useLocalStorage("isCorrect", false);
  const [menuNoticeOpened, setMenuNoticeOpened] = useLocalStorage(
    "menuNoticeOpened",
    true
  );
  // const [subscribeChannelValue, setSubscribeChannelValue] =
  //   useState("phone_number");
  const [contributionEmojis, setContributionEmojis] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [dateCompareArray, setDateCompareArray] = useLocalStorage(
    "dateCompareArray",
    []
  );
  const [visible, setVisible] = useState(false);

  //For the Archive feature.
  const archiveGuessRef = useRef();
  const [archiveChallengeOpened, setArchiveChallengeOpened] = useState(false);
  const [archiveIsComplete, setArchiveIsComplete] = useState(false);
  const [archiveIsCorrect, setArchiveIsCorrect] = useState(false);
  const [archiveScoreData, setArchiveScoreData] = useLocalStorage(
    "archiveScoreData",
    [{ count: 0, date: "2022-09-14", level: 0 }]
  );
  const [archiveGuess, setArchiveGuess] = useState("");
  const [archiveHintRevealedCount, setArchiveHintRevealedCount] = useState(0);
  const [archiveGuessCount, setArchiveGuessCount] = useState(0);
  const [archivePrevGuesses, setArchivePrevGuesses] = useState([]);
  const [archiveHint1Shown, setArchiveHint1Shown] = useState(false);
  const [archiveHint2Shown, setArchiveHint2Shown] = useState(false);
  const [archiveHint1ModalOpened, setArchiveHint1ModalOpened] = useState(false);
  const [archiveHint2ModalOpened, setArchiveHint2ModalOpened] = useState(false);
  const [archiveCompleteModalOpened, setArchiveCompleteModalOpened] =
    useState(false);
  // const [completeModalOpened, setCompleteModalOpened] = useState(false);
  const [archiveChallenge, setArchiveChallenge] = useState("");
  const archiveAnswersArray = archiveChallenge.answers
    ?.toUpperCase()
    .split(";")
    .map((answer) => answer.replace(/[,.'\s]/g, "").trim());
  const [hasShared, setHasShared] = useState(true); //Have they ever shared?
  const [archiveArray, setArchiveArray] = useState([]); //Array of all challenges completed
  const [privacyPolicyModalOpened, setPrivacyPolicyModalOpened] =
    useState(false);

  const {
    answerDescription,
    answers,
    category,
    hint1,
    hint2,
    imageUrl,
    prompt,
    rating,
    submittedBy,
  } = todaysChallenge;

  //1. Convert to uppercase. 2. Delimit by ;. 3. Remove commas, spaces, and periods.

  //Const officialAnswer is the first answer in 'answers'
  const officialAnswer = answers?.toUpperCase().split(";")[0];

  function daysBetween(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    start.setHours(0, 0, 0, 0); // Set the time to midnight to ignore time of day
    end.setHours(0, 0, 0, 0);
    const diffTime = Math.abs(end - start);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  function formatArchiveDate(input) {
    const [year, month, day] = input.split("-");
    const date = new Date(year, month - 1, day);
    const options = { month: "short", day: "numeric" };
    return (
      date.toLocaleDateString("en-US", options) + ", " + date.getFullYear()
    );
  }

  function getCountForDate(date) {
    const dateData = scoreData.find((data) => data.date === date);
    if (dateData) {
      return dateData.count;
    }
    return null;
  }

  function getArchiveCountForDate(date) {
    const dateData = archiveScoreData?.find((data) => data.date === date);
    if (dateData) {
      return dateData.count;
    }
    return false;
  }

  const answersArray = answers
    ?.toUpperCase()
    .split(";")
    .map((answer) => answer.replace(/[,.'\s]/g, "").trim());

  const guessesRemainingCount = 3 - guessCount;
  const userScore = 6 - guessCount - hintRevealedCount;
  const pointsPossible = 5 - guessCount - hintRevealedCount;

  //Archive point system
  const archiveGuessesRemainingCount = 3 - archiveGuessCount;
  const archiveUserScore = 6 - archiveGuessCount - archiveHintRevealedCount;
  const archivePointsPossible =
    5 - archiveGuessCount - archiveHintRevealedCount;

  //If previous date count is > 0, increment winStreak; otherwise, set to 1. Only call in "win" scenario.
  // function calculateWinStreak() {
  //   if (
  //     scoreData[scoreData.length - 1].date === yesterdaysDateStats &&
  //     scoreData[scoreData.length - 2].count > 0
  //   ) {
  //     setWinStreak(winStreak + 1);
  //   } else {
  //     setWinStreak(1);
  //   }
  // }

  const winStreak = scoreData.reduce((acc, cur) => {
    if (cur.count > 0) {
      acc.push(cur);
    } else {
      acc = [];
    }

    return acc;
  }, []).length;

  const winCount = scoreData.filter((score) => score.count > 0).length;

  const totalPoints = scoreData.reduce((acc, cur) => {
    return acc + cur.count;
  }, 0);

  useEffect(() => {
    async function dailyChallenge() {
      await axios
        .get(`${baseUrl}/dailychallenge`, {
          headers: {
            "ms-time-zone-offset": msTimeZoneOffset,
            "todays-date": todaysDateStats,
          },
        })
        .then((res) => {
          setTodaysChallenge(res.data);
          // setDateCompareArray((prev) => [
          //   //Use prev only if the length of prev is 1 or greater
          //   prev.length > 0 && prev[dateCompareArray.length - 1],
          //   todaysDateStats,
          // ]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    dailyChallenge();
    !howToPlayModalOpened &&
      setTimeout(() => {
        focusGuessAfterIncorrect();
      }, 50);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Reset the game when there's a new daily challenge
  function resetGame() {
    setIsComplete(false);
    setCompleteModalOpened(false);
    setGuess("");
    guessLettersArray.length = 0;
    setHintRevealedCount(0);
    setGuessCount(0);
    setPrevGuesses([]);
    setHint1Shown(false);
    setHint2Shown(false);
    focusGuessAfterIncorrect();
  }

  function resetArchiveGame() {
    setArchiveIsComplete(false);
    setArchiveCompleteModalOpened(false);
    setArchiveGuess("");
    archiveGuessLettersArray.length = 0;
    setArchiveHintRevealedCount(0);
    setArchiveGuessCount(0);
    setArchivePrevGuesses([]);
    setArchiveHint1Shown(false);
    setArchiveHint2Shown(false);
    focusArchiveGuessAfterIncorrect();
  }

  //Create an array to compare to see if todaysDateStats is the same as the previous entry in the array
  useEffect(() => {
    setDateCompareArray((prev) => [prev, todaysDateStats]);
    // If the date of the last entry is different from the second-to-last entry, setIsComplete to false.
    if (dateCompareArray[dateCompareArray?.length - 1] !== todaysDateStats) {
      resetGame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !isTouch && setMenuNoticeOpened(false);
    setTimeout(() => {
      setMenuNoticeOpened(false);
    }, 4000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const waitlistForm = useForm({
    initialValues: {
      email: "",
      termsOfService: false,
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  // const dailyEmailSubscribeForm = useForm({
  //   initialValues: {
  //     email: "",
  //     termsOfService: false,
  //   },

  //   validate: {
  //     email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
  //   },
  // });

  // const dailySmsSubscribeForm = useForm({
  //   initialValues: {
  //     phone_number: "",
  //     termsOfService: false,
  //   },

  //   validate: {
  //     phone_number: (value) =>
  //       /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/.test(
  //         value
  //       )
  //         ? null
  //         : "Invalid phone number",
  //   },
  // });

  const contributeForm = useForm({
    initialValues: {
      prompt: "",
      answer: "",
      category: "",
      // hint1: "",
      // hint2: "",
      name: "",
      email: "",
      status: "Reviewing",
    },
  });

  const handleContributeField = (e) => {
    contributionEmojiArray.push(e.native);
    setContributionEmojis(contributionEmojiArray.join(""));
    contributeForm.setFieldValue("prompt", contributionEmojiArray.join(""));
  };

  const handleContributeSubmit = async (e) => {
    e.preventDefault();
    setVisible(true);
    await axios
      .post(`${baseUrl}/contribute`, contributeForm.values)
      .then((res) => {
        if (res.data === "OK") {
          setVisible(false);
          console.log(res);
        }
      });
    contributeForm.reset();
    contributionEmojiArray.length = 0;
    showNotification({
      title: "Thank you!",
      message: "Submit another if you'd like!",
      autoClose: 3000,
    });
  };

  const handleWaitlistSubmit = () => {
    axios.post(`${baseUrl}/submit`, waitlistForm.values);
    setWelcomeModalOpened(false);
    showNotification({
      title: "Success!",
      message: "We'll send you the daily challenge once the game is live.",
      autoClose: 2000,
      position: "top-right",
    });
  };

  // const handleDailyEmailSubscribe = () => {
  //   axios.post(
  //     `${baseUrl}/dailyemailsubscribe`,
  //     dailyEmailSubscribeForm.values
  //   );

  //   showNotification({
  //     title: "✅",
  //     message:
  //       "You're on the wait list. You'll automatically be subscribed once this feature launches.",
  //     autoClose: 2000,
  //     position: "top-right",
  //   });
  // };

  // const handleDailySmsSubscribe = () => {
  //   axios.post(`${baseUrl}/dailysmssubscribe`, dailySmsSubscribeForm.values);

  //   showNotification({
  //     title: "✅",
  //     message:
  //       "You're on the wait list. You'll automatically be subscribed once this feature launches.",
  //     autoClose: 2000,
  //     position: "top-right",
  //   });
  // };

  //Daily Challenge game play
  const handleClick = (e) => {
    if (!hasTouchRef.current) {
      guessLettersArray.push(e.target.value);
      setGuess(guessLettersArray.join(""));
      // hasTouchRef.current = false;
    }
  };

  const handleTouchStart = (e) => {
    hasTouchRef.current = true;
    guessLettersArray.push(e.target.value);
    setGuess(guessLettersArray.join(""));
  };

  const handleBackspace = () => {
    guessLettersArray.pop();
    setGuess(guessLettersArray.join(""));
  };

  const handleClear = (e) => {
    guessLettersArray.length = 0;
    setGuess("");
    e.target.blur();
  };

  const keyDownHandler = (e) => {
    if (e.key === "Enter") {
      handleGuessSubmit();
      e.preventDefault();
    }
  };

  useEffect(() => {
    !isComplete && guessRef.current.focus();
    window.scroll(100, 100);
    thenScrollToTheTop();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hint1ModalOpened, hint2ModalOpened, isComplete]);

  const scrollToTheTop = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const scrollToTheBottom = () => {
    window.scrollTo({ top: 100, left: 0 });
  };

  const thenScrollToTheTop = () => {
    setTimeout(() => {
      scrollToTheTop();
    }, 50);
  };

  const thenScrollToTheBottom = () => {
    setTimeout(() => {
      scrollToTheBottom();
    }, 50);
  };

  const focusGuess = () => {
    !isComplete && guessRef.current.focus();
    window.scroll(100, 100);
    thenScrollToTheTop();
  };

  useEffect(() => {
    focusGuess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hint1ModalOpened, hint2ModalOpened, guessCount]);

  // const thenFocusGuess = () => {
  //   !isComplete &&
  //     setTimeout(() => {
  //       guessRef.current.focus();
  //       window.scroll(100, 100);
  //       thenScrollToTheTop();
  //     }, 50);
  // };

  const focusGuessAfterIncorrect = () => {
    !isComplete &&
      // setTimeout(() => {
      guessRef.current.focus();
    thenScrollToTheTop();
    thenScrollToTheBottom();
    thenScrollToTheTop();
    // }, 50);
  };

  function countHintReveals() {
    const maxHintRevealCount = 2;
    setHintRevealedCount(Math.min(hintRevealedCount + 1, maxHintRevealCount));
  }

  const handleGuessSubmit = async () => {
    //Win!
    if (answersArray.includes(guess.replace(/[,.'\s]/g, "").toUpperCase())) {
      setGuessCount(guessCount + 1);
      setIsComplete(true);
      setIsCorrect(true);
      setCompleteModalOpened(true);
      setScoreData((prev) => [
        ...prev,
        {
          count: userScore - 1, //ActivityCalendar runs 0-4
          date: todaysDateStats,
          level: userScore - 1, //ActivityCalendar runs 0-4
        },
      ]);
      // await submitStats({ isCorrect: true, userScore: userScore - 1 });
      return;
    }

    //Duplicate guess!
    if (prevGuesses.includes(guess.replace(/[,.']/g, "").toUpperCase())) {
      showNotification({
        title: "Oops!",
        message: "You already guessed that.",
        autoClose: 2000,
        position: "top-right",
      });
      focusGuess();
      return;
    }

    //Blank!
    if (guess.length === 0) {
      showNotification({
        title: "Oops!",
        message: "You must enter a guess.",
        autoClose: 2000,
        position: "top-right",
      });
      focusGuessAfterIncorrect();
      return;

      //Incorrect!
    } else if (
      answersArray.includes(guess.replace(/[,.']/g, "").toUpperCase()) === false
    ) {
      //Incorrect and lose!
      if (guessesRemainingCount === 1) {
        setGuessCount(guessCount + 1);
        setIsComplete(true);
        setPrevGuesses((prevGuesses) => [...prevGuesses, guess]);
        setGuess("");
        setIsCorrect(false);
        setCompleteModalOpened(true);
        setScoreData((prev) => [
          ...prev,
          {
            count: 0,
            date: todaysDateStats,
            level: 0,
          },
        ]);
        // await submitStats({ isCorrect: false, userScore: 0 });
        return;
      }
      //Incorrect but guess again!
      else {
        setIsComplete(false);
        setIsCorrect(false);
        setGuess("");
        guessLettersArray.length = 0;
        setGuessCount(guessCount + 1);
        setPrevGuesses((prevGuesses) => [...prevGuesses, guess]);
        focusGuessAfterIncorrect();
      }
    }
  };

  //Create a function that makes a POST request to Airtable at the /submitstats route recording: prompt, answer, prevGuesses, guessCount, hintRevealedCount, isCorrect, and userScore. The function is called in the handleSubmit scenario where isCorrect is true.
  // async function submitStats(input) {
  //   await axios
  //     .post(`${baseUrl}/submitstats`, {
  //       prompt,
  //       officialAnswer,
  //       prevGuesses,
  //       guess,
  //       guessCount,
  //       hintRevealedCount,
  //       isCorrect: input.isCorrect,
  //       userScore: input.userScore,
  //     })
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   trackEvent("Games Played");
  // }

  async function getArchive() {
    setVisible(true);
    await axios
      .get(`${baseUrl}/archive`, {
        headers: {
          "ms-time-zone-offset": msTimeZoneOffset,
          "todays-date": todaysDateStats,
        },
      })
      .then((res) => {
        setArchiveArray(res.data);
        setVisible(false);
      });
  }

  // async function submitForfeit() {
  //   setGuessCount(guessCount + 1);
  //   setIsComplete(true);
  //   setIsCorrect(false);
  //   setCompleteModalOpened(true);
  //   setScoreData((prev) => [
  //     ...prev,
  //     {
  //       count: 0,
  //       date: todaysDateStats,
  //       level: 0,
  //     },
  //   ]);
  //   trackEvent("Forfeit");
  //   await axios
  //     .post(`${baseUrl}/submitstats`, {
  //       prompt,
  //       officialAnswer,
  //       prevGuesses,
  //       guess,
  //       guessCount,
  //       hintRevealedCount,
  //       isCorrect: false,
  //       userScore: 0,
  //     })
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   setPrevGuesses((prevGuesses) => [...prevGuesses, guess]);
  //   setGuess("");
  // }

  const shareMessage = `${category}: ${prompt} \nMy score: ${
    isCorrect ? emojiByScore(userScore) : emojiByScore(0)
  }\n\nPlay EmojiClue at www.emojiclue.com!`;

  const onShare = async () => {
    try {
      const result = await Share.share({
        message: shareMessage,
      });
      if (result?.action === Share.sharedAction) {
        if (result?.activityType) {
          console.log(`Shared with activity type of ${result?.activityType}`);
        } else {
          console.log("Shared");
        }
      } else if (result?.action === Share.dismissedAction) {
        console.log("Dismissed");
      }
    } catch (error) {
      console.log(error.message);
    }
    console.log("Shared!");
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareMessage);
    showNotification({
      title: "Copied to clipboard!",
      message: `Now paste your score ${
        isCorrect ? emojiByScore(userScore) : emojiByScore(0)
      } anywhere your 💙 desires!`,
      autoClose: 4000,
      position: "top-right",
    });
  };

  //START Archive game play

  const handleArchiveClick = (e) => {
    if (!hasTouchRef.current) {
      archiveGuessLettersArray.push(e.target.value);
      setArchiveGuess(archiveGuessLettersArray.join(""));
    }
  };

  const handleArchiveTouchStart = (e) => {
    hasTouchRef.current = true;
    archiveGuessLettersArray.push(e.target.value);
    setArchiveGuess(archiveGuessLettersArray.join(""));
  };

  const handleArchiveBackspace = () => {
    archiveGuessLettersArray.pop();
    setArchiveGuess(archiveGuessLettersArray.join(""));
  };

  const handleArchiveClear = (e) => {
    archiveGuessLettersArray.length = 0;
    setArchiveGuess("");
    e.target.blur();
  };

  const archiveKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      handleArchiveGuessSubmit();
      e.preventDefault();
    }
  };

  // useEffect(() => {
  //   !archiveIsComplete && archiveGuessRef.current.focus();
  //   window.scroll(100, 100);
  //   thenScrollToTheTop();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [archiveHint1ModalOpened, archiveHint2ModalOpened, archiveIsComplete]);

  const focusArchiveGuess = () => {
    if (!archiveIsComplete && archiveGuessRef?.current) {
      archiveGuessRef?.current.focus();
      window.scroll(100, 100);
      thenScrollToTheTop();
    } else {
      console.log("no archiveGuessRef");
    }
    return;
  };

  //Create a useEffect that calls focusArchiveGuess any time a HintModal is closed.
  useEffect(() => {
    focusArchiveGuess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveHint1ModalOpened, archiveHint2ModalOpened]);

  const focusArchiveGuessAfterIncorrect = () => {
    !archiveIsComplete &&
      // setTimeout(() => {
      archiveGuessRef.current.focus();
    thenScrollToTheTop();
    thenScrollToTheBottom();
    thenScrollToTheTop();
    // }, 50);
  };

  function countArchiveHintReveals() {
    const maxHintRevealCount = 2;
    setArchiveHintRevealedCount(
      Math.min(archiveHintRevealedCount + 1, maxHintRevealCount)
    );
  }

  const handleArchiveGuessSubmit = () => {
    //Win!
    if (
      archiveAnswersArray.includes(
        archiveGuess.replace(/[,.'\s]/g, "").toUpperCase()
      )
    ) {
      setArchiveGuessCount(archiveGuessCount + 1);
      setArchiveIsComplete(true);
      setArchiveIsCorrect(true);
      setArchiveCompleteModalOpened(true);
      setArchiveScoreData((prev) => [
        ...prev,
        {
          count: archiveUserScore - 1, //ActivityCalendar runs 0-4
          date: archiveChallenge.releaseDate, //Need this to be item.releaseDate
          level: archiveUserScore - 1, //ActivityCalendar runs 0-4
        },
      ]);
      trackEvent("Games Played (Archive)");
      return;
    }

    //Duplicate guess!
    if (
      archivePrevGuesses.includes(
        archiveGuess.replace(/[,.']/g, "").toUpperCase()
      )
    ) {
      showNotification({
        title: "Oops!",
        message: "You already guessed that.",
        autoClose: 2000,
        position: "top-right",
      });
      focusArchiveGuess();
      return;
    }

    //Blank!
    if (archiveGuess.length === 0) {
      showNotification({
        title: "Oops!",
        message: "You must enter a guess.",
        autoClose: 2000,
        position: "top-right",
      });
      focusArchiveGuessAfterIncorrect();
      return;

      //Incorrect!
    } else if (
      archiveAnswersArray.includes(
        archiveGuess.replace(/[,.']/g, "").toUpperCase()
      ) === false
    ) {
      //Incorrect and lose!
      if (archiveGuessesRemainingCount === 1) {
        setArchiveGuessCount(archiveGuessCount + 1);
        setArchiveIsComplete(true);
        setArchivePrevGuesses((archivePrevGuesses) => [
          ...archivePrevGuesses,
          archiveGuess,
        ]);
        setArchiveGuess("");
        setArchiveIsCorrect(false);
        setArchiveCompleteModalOpened(true);
        setArchiveScoreData((prev) => [
          ...prev,
          {
            count: 0, //ActivityCalendar runs 0-4
            date: archiveChallenge.releaseDate, //Need this to be item.releaseDate
            level: 0, //ActivityCalendar runs 0-4
          },
        ]);
        trackEvent("Games Played (Archive)");
        return;
      }
      //Incorrect but guess again!
      else {
        setArchiveIsComplete(false);
        setArchiveIsCorrect(false);
        setArchiveGuess("");
        archiveGuessLettersArray.length = 0;
        setArchiveGuessCount(archiveGuessCount + 1);
        setArchivePrevGuesses((archivePrevGuesses) => [
          ...archivePrevGuesses,
          archiveGuess,
        ]);
        focusArchiveGuessAfterIncorrect();
      }
    }
  };

  const unlockShareMessage = `${category}: ${prompt}
  \n\nPlay today's EmojiClue at www.emojiclue.com!`;

  const unlockOnShare = async () => {
    try {
      const result = await Share.share({
        message: unlockShareMessage,
      });
      if (result?.action === Share.sharedAction) {
        if (result?.activityType) {
          console.log(`Shared with activity type of ${result?.activityType}`);
        } else {
          console.log("Shared");
        }
      } else if (result?.action === Share.dismissedAction) {
        console.log("Dismissed");
      }
    } catch (error) {
      console.log(error.message);
    }
    console.log("Shared!");
  };

  const archiveShareMessage = `EmojiClue ${daysBetween(
    "2022-12-04",
    archiveChallenge.releaseDate
  )}: ${archiveChallenge.prompt} \nMy score: ${
    archiveIsCorrect ? emojiByScore(archiveUserScore) : emojiByScore(0)
  }\n\nPlay the EmojiClue archive at www.emojiclue.com!`;

  const archiveOnShare = async () => {
    try {
      const result = await Share.share({
        message: archiveShareMessage,
      });
      if (result?.action === Share.sharedAction) {
        if (result?.activityType) {
          console.log(`Shared with activity type of ${result?.activityType}`);
        } else {
          console.log("Shared");
        }
      } else if (result?.action === Share.dismissedAction) {
        console.log("Dismissed");
      }
    } catch (error) {
      console.log(error.message);
    }
    console.log("Shared!");
  };

  const archiveCopyToClipboard = () => {
    navigator.clipboard.writeText(archiveShareMessage);
    showNotification({
      title: "Copied to clipboard!",
      message: `Now paste your score ${
        archiveIsCorrect ? emojiByScore(archiveUserScore) : emojiByScore(0)
      } anywhere your 💙 desires!`,
      autoClose: 4000,
      position: "top-right",
    });
  };

  const unlockCopyToClipboard = () => {
    navigator.clipboard.writeText(unlockShareMessage);
    showNotification({
      title: "Copied to clipboard!",
      message: `Now paste the link anywhere your 💙 desires!`,
      autoClose: 4000,
      position: "top-right",
    });
  };

  //END Archive game play

  const winScore = `${emojiByScore(userScore)}`;
  const loseScore = emojiByScore(0);

  const archiveWinScore = `${emojiByScore(archiveUserScore)}`;

  function HintModal({
    hint1,
    hint2,
    hint1ModalOpened,
    hint2ModalOpened,
    setHint1ModalOpened,
    setHint2ModalOpened,
    focusGuess,
    guessRef,
  }) {
    return (
      <>
        <Modal
          opened={hint1ModalOpened}
          onClose={() => {
            setHint1ModalOpened(false);
          }}
          title="😉 Hint #1"
          size="md"
          centered
        >
          <Text size="md" className="hint">
            {hint1}
          </Text>
        </Modal>
        <Modal
          opened={hint2ModalOpened}
          onClose={() => {
            setHint2ModalOpened(false);
          }}
          title="😉 Hint #2"
          size="md"
          centered
        >
          <Text size="xl" className="hint">
            {hint2}
          </Text>
        </Modal>
      </>
    );
  }

  function ArchiveHintModal({
    hint1,
    hint2,
    hint1ModalOpened,
    hint2ModalOpened,
    setHint1ModalOpened,
    setHint2ModalOpened,
    focusArchiveGuess,
    archiveGuessRef,
  }) {
    return (
      <>
        <Modal
          opened={archiveHint1ModalOpened}
          onClose={() => {
            setArchiveHint1ModalOpened(false);
            // focusArchiveGuess();
          }}
          title="😉 Hint #1"
          size="md"
          centered
        >
          <Text size="md" className="hint">
            {hint1}
          </Text>
        </Modal>
        <Modal
          opened={archiveHint2ModalOpened}
          onClose={() => {
            setArchiveHint2ModalOpened(false);
          }}
          title="😉 Hint #2"
          size="md"
          centered
        >
          <Text size="xl" className="hint">
            {hint2}
          </Text>
        </Modal>
      </>
    );
  }

  function CompleteModal({ winScore, loseScore }) {
    useEffect(() => {
      if (isCorrect) {
        setHeaderEmoji("🥳");
      } else {
        setHeaderEmoji("😭");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCorrect]);

    return (
      <Modal
        overlayOpacity={0.55}
        overlayBlur={1}
        centered
        padding="xs"
        opened={completeModalOpened}
        onClose={() => setCompleteModalOpened(false)}
        title={<b>{messageByScore(isCorrect ? userScore : 0)}</b>}
        // style={{
        //   display: "flex",
        //   alignSelf: "center",
        // }}
        // styles={{
        //   inner: {
        //     padding: "16px",
        //   },
        //   header: {
        //     margin: "0px -9px 6px 0px",
        //   },
        // }}
      >
        <Card p="xs" radius="md">
          {imageUrl && (
            <Card.Section>
              <Container style={{ display: "flex", justifyContent: "center" }}>
                <Image src={imageUrl} fit="contain" alt="Poster" />
              </Container>
            </Card.Section>
          )}
          <Space h="sm" />
          <Group position="apart">
            <Text weight={500}>
              {officialAnswer} {prompt}
            </Text>
            {rating && (
              <Badge color="yellow" variant="light">
                ⭐{rating}
              </Badge>
            )}
          </Group>
          <Text
            size="sm"
            color="dimmed"
            style={{
              lineHeight: "1.2",
            }}
          >
            {answerDescription}
          </Text>

          {isCorrect ? <ConfettiBurst /> : <EmojiBurst />}
          <Space h="sm" />
          <Text weight={600}>
            Your Score: {isCorrect ? winScore : loseScore}
          </Text>

          {/* <Center style={{ paddingLeft: "16px" }}>
              <ECActivityCalendar scoreData={scoreData} />
            </Center> */}

          {/* <Center>
              <Group>
                <Text size="sm">
                  Wins: <b>{winCount}</b>🏆
                </Text>
                <Text size="sm">
                  Points: <b>{totalPoints}</b>⭐
                </Text>
                <Text size="sm">
                  Streak: <b>{winStreak}</b>🔥
                </Text>
              </Group>
            </Center> */}

          {/* <CountDown /> */}

          <Space h="sm" />
          <Button
            color="blue"
            variant="outline"
            fullWidth
            mt="md"
            style={{ width: "100%", margin: "2px", fontSize: "16px" }}
            onClick={() => {
              setCompleteModalOpened(false);
              setNavbarOpened(true);
              setAccordionValueOpen("archive");
              getArchive();
            }}
          >
            Play prior challenges 🏋️
          </Button>
          <Button
            color="green"
            onClick={() => {
              trackEvent("Share");
              isTouch ? onShare() : copyToClipboard();
            }}
            fullWidth
            mt="md"
            style={{ width: "100%", margin: "2px", fontSize: "16px" }}
          >
            Share your score!{" "}
            {isCorrect ? emojiByScore(userScore) : emojiByScore(0)}
          </Button>
        </Card>
        <Group position="apart">
          <Text size="xs" color="dimmed" italic>
            {submittedBy && `✍️ by ${submittedBy} `}
          </Text>
          <Text
            size="xs"
            color="blue"
            underline
            onClick={() => {
              setCompleteModalOpened(false);
              setNavbarOpened(true);
              setAccordionValueOpen("contribute");
            }}
          >
            Submit a clue
          </Text>
        </Group>
      </Modal>
    );
  }

  function ArchiveCompleteModal({ winScore, loseScore }) {
    return (
      <Container>
        <Modal
          overlayOpacity={0.55}
          overlayBlur={1}
          centered
          padding="xs"
          opened={archiveCompleteModalOpened}
          onClose={() => {
            setArchiveCompleteModalOpened(false);
            resetArchiveGame();
            setArchiveChallengeOpened(false);
          }}
          title={
            <b>{messageByScore(archiveIsCorrect ? archiveUserScore : 0)}</b>
          }
          style={{
            display: "flex",
            alignSelf: "center",
          }}
          styles={{
            inner: {
              padding: "16px",
            },
            header: {
              margin: "0px -9px 6px 0px",
            },
          }}
        >
          <Card p="xs" radius="md">
            {archiveChallenge.imageUrl && (
              <Card.Section>
                <Container
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Image
                    src={archiveChallenge.imageUrl}
                    fit="contain"
                    alt="Poster"
                  />
                </Container>
              </Card.Section>
            )}
            <Space h="sm" />
            <Group position="apart">
              <Text weight={500}>
                {archiveChallenge.answers?.split(";")[0]}{" "}
                {archiveChallenge.prompt}
              </Text>
              {archiveChallenge.rating && (
                <Badge color="yellow" variant="light">
                  ⭐{archiveChallenge.rating}
                </Badge>
              )}
            </Group>
            <Text
              size="sm"
              color="dimmed"
              style={{
                lineHeight: "1.2",
              }}
            >
              {archiveChallenge.answerDescription}
            </Text>

            {archiveIsCorrect ? <ConfettiBurst /> : <EmojiBurst />}
            <Space h="sm" />
            <Text weight={600}>
              Your Score: {archiveIsCorrect ? winScore : loseScore}
            </Text>
            <Button
              color="green"
              onClick={() => {
                trackEvent("Share (Archive)");
                isTouch ? archiveOnShare() : archiveCopyToClipboard();
              }}
              fullWidth
              mt="md"
              style={{ width: "100%", margin: "2px", fontSize: "16px" }}
            >
              Share your score!{" "}
              {archiveIsCorrect
                ? emojiByScore(archiveUserScore)
                : emojiByScore(0)}
            </Button>
          </Card>
          <Group position="apart">
            <Text size="xs" color="dimmed" italic>
              {archiveChallenge.submittedBy &&
                `✍️ by ${archiveChallenge.submittedBy} `}
            </Text>
            <Text
              size="xs"
              color="blue"
              underline
              onClick={() => {
                setArchiveCompleteModalOpened(false);
                setNavbarOpened(true);
                setAccordionValueOpen("contribute");
              }}
            >
              Submit a clue
            </Text>
          </Group>
        </Modal>
      </Container>
    );
  }

  function YourScore({ winScore, loseScore, isCorrect }) {
    return (
      <>
        <Text weight={600}>Your Score: {isCorrect ? winScore : loseScore}</Text>
      </>
    );
  }

  return (
    <div className="App">
      <NotificationsProvider position="top-right">
        <AppShell
          // padding="none"
          navbarOffsetBreakpoint="sm"
          asideOffsetBreakpoint="sm"
          navbar={
            <Navbar
              className="App-Shell-Navbar"
              p="md"
              hiddenBreakpoint="sm"
              hidden={!navbarOpened}
              width={{ sm: 370, lg: 370 }}
              style={{ textAlign: "left" }}
            >
              <Navbar.Section grow component={ScrollArea}>
                <Accordion
                  variant="filled"
                  value={accordionValueOpen}
                  onChange={setAccordionValueOpen}
                >
                  <Accordion.Item value="howToPlay">
                    <Accordion.Control>🎮 How to Play</Accordion.Control>
                    <Accordion.Panel>
                      <HowToPlayText />
                      <Space h="sm" />
                      <Text size="sm">
                        For best results, sound out guesses <u>aloud</u>!
                      </Text>
                      <Space h="sm" />
                      <Text size="sm">
                        <b>Scoring</b>
                        <List size="sm">
                          <List.Item>
                            You have 3 guesses and 2 hints to get the answer.
                          </List.Item>
                          <List.Item>
                            Each guess and hint costs 1 point.
                          </List.Item>
                          <List.Item>
                            Below is a list of possible scores:
                          </List.Item>
                        </List>
                        🥇 5pts: 1 guess + 0 hints<br></br>
                        🥈 4pts: 1 guess + 1 hint / 2 guesses + 0 hints
                        <br></br>
                        🥉 3pts: 2 guesses + 1 hint / 1 guess + 2 hints
                        <br></br>
                        🥔 2pts: 2 guesses + 2 hints / 3 guesses + 1 hint
                        <br></br>
                        🪨 1pt: 3 guesses + 2 hints<br></br>
                        💩 0pts: 3 incorrect guesses
                      </Text>
                    </Accordion.Panel>
                  </Accordion.Item>
                  <Accordion.Item value="archive">
                    <Accordion.Control onClick={() => getArchive()}>
                      📜 Archive {!hasShared ? "🔒" : "🔓"}
                    </Accordion.Control>

                    <Accordion.Panel>
                      <LoadingOverlay visible={visible} />
                      {!hasShared ? (
                        <div>
                          <Text>
                            <List spacing="xs">
                              <List.Item>
                                Ok, I have a favor to ask 😃
                              </List.Item>
                              <List.Item>
                                I've worked hard to build this little game 👷‍♂️
                              </List.Item>
                              <List.Item>
                                And I could really use your help spreading the
                                word 📣
                              </List.Item>
                              <List.Item>
                                Would you take 1 minute to share EmojiClue via
                                Twitter, Facebook, or SMS? 📱
                              </List.Item>
                              <List.Item>
                                It would mean the world to me 🌎
                              </List.Item>
                              <List.Item>
                                I'll unlock the full archive for you right away
                                as a thank you! 🙏
                              </List.Item>
                            </List>
                          </Text>
                          <Space h="sm" />
                          <Group
                            position="left"
                            style={{ paddingLeft: "24px" }}
                          >
                            <i>-Aubrey</i>
                          </Group>

                          <br></br>
                          <Button
                            onClick={() => {
                              isTouch
                                ? unlockOnShare()
                                : unlockCopyToClipboard();
                              setHasShared(true);
                              trackEvent("Share (Unlock)");
                            }}
                          >
                            Share and unlock{" "}
                            {daysBetween("2022-12-04", todaysDate)} clues!
                          </Button>
                          <br></br>
                          <br></br>
                        </div>
                      ) : (
                        <>
                          <Text>
                            Thanks for sharing EmojiClue! You've unlocked the
                            archive! 🙌
                          </Text>
                          <br></br>
                        </>
                      )}
                      {archiveArray.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <Card
                              shadow="sm"
                              p="xs"
                              radius="md"
                              withBorder
                              style={{ marginBottom: "3px" }}
                            >
                              <Group position="apart">
                                <Text weight={500}>
                                  #{daysBetween("2022-12-04", item.releaseDate)}
                                  :{" "}
                                  <span style={{ fontSize: "1.25em" }}>
                                    {item.prompt}
                                  </span>
                                </Text>
                              </Group>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {(getArchiveCountForDate(item.releaseDate) !==
                                  false || getCountForDate(item.releaseDate)) >
                                  0 && (
                                  <>
                                    <Text weight={500}>
                                      {item.answers?.split(";")[0]}
                                    </Text>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexGrow: 1,
                                        justifyContent: "flex-end",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      <span style={{ fontSize: "1.4em" }}>
                                        {emojiByScore(
                                          // The greater of item.releaseDate and getArchiveCountForDate(item.releaseDate)
                                          Math.max(
                                            getCountForDate(item.releaseDate),
                                            getArchiveCountForDate(
                                              item.releaseDate
                                            )
                                          )
                                        )}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div>
                                <Group position="apart">
                                  <Group>
                                    <Text size="sm" color="dimmed" italic>
                                      {formatArchiveDate(item.releaseDate)}
                                    </Text>
                                    <Badge color="violet" size="sm">
                                      {emojiByCategory(item.category)}{" "}
                                      {item.category}
                                    </Badge>
                                  </Group>
                                  {(getCountForDate(item.releaseDate) === 0 ||
                                    getCountForDate(item.releaseDate) ===
                                      null) &&
                                    getArchiveCountForDate(item.releaseDate) ===
                                      false && (
                                      <Group position="right">
                                        <Button
                                          size="xs"
                                          color="green"
                                          disabled={!hasShared}
                                          onClick={() => {
                                            setArchiveChallengeOpened(true);
                                            setArchiveChallenge(item);
                                            setVisible(true);
                                            focusArchiveGuess();
                                            setTimeout(() => {
                                              setVisible(false);
                                            }, 1000);
                                          }}
                                        >
                                          Play
                                        </Button>
                                      </Group>
                                    )}
                                </Group>
                              </div>
                            </Card>
                          </React.Fragment>
                        );
                      })}
                    </Accordion.Panel>
                  </Accordion.Item>
                  <Accordion.Item value="contribute">
                    <Accordion.Control>🎨 Contribute</Accordion.Control>
                    <Accordion.Panel>
                      <LoadingOverlay visible={visible} />
                      <Text size="md">
                        Have a clever clue? Submit it and if it's chosen, your
                        name will be featured on the Daily Challenge
                      </Text>
                      <Space h="sm" />
                      <form onSubmit={handleContributeSubmit}>
                        <TextInput
                          required
                          ref={contributionEmojiRef}
                          size="md"
                          label="Emojis"
                          value={contributionEmojis}
                          placeholder="❔❔❔"
                          onClick={() => setShowPicker(true)}
                          {...contributeForm.getInputProps("prompt")}
                        />

                        <Modal
                          opened={showPicker && !isTouch}
                          onClose={() => {
                            setShowPicker(false);
                          }}
                          closeButtonLabel
                          closeOnClickOutside
                          closeOnEscape
                          overlayOpacity={0}
                          padding="xs"
                        >
                          <Picker
                            data={data}
                            perLine="11"
                            maxFrequentRows="1"
                            onEmojiSelect={(e) => {
                              handleContributeField(e);
                            }}
                          />
                        </Modal>

                        <TextInput
                          ref={contributionAnswerRef}
                          required
                          size="md"
                          label="Answer"
                          placeholder="Correct Answer"
                          {...contributeForm.getInputProps("answer")}
                        />
                        <NativeSelect
                          required
                          size="md"
                          data={[
                            "Movies & TV",
                            "Songs & Artists",
                            "Books",
                            "Celebrities",
                            "Sports",
                            "Quotes & Phrases",
                            "Brands",
                            "Other",
                          ]}
                          placeholder="Select category"
                          label="Category"
                          {...contributeForm.getInputProps("category")}
                        />
                        {/* <TextInput
                          size="md"
                          label="Hint 1"
                          placeholder="❔ (optional)"
                          {...contributeForm.getInputProps("hint1")}
                        />
                        <TextInput
                          size="md"
                          label="Hint 2"
                          placeholder="❔ (optional)"
                          {...contributeForm.getInputProps("hint2")}
                        /> */}
                        <TextInput
                          size="md"
                          label="Name"
                          placeholder="Emily O. Ji (optional)"
                          {...contributeForm.getInputProps("name")}
                        />
                        <TextInput
                          size="md"
                          label="Email (required to win gift card)"
                          placeholder="emily@gmail.com"
                          {...contributeForm.getInputProps("email")}
                        />
                        <Space h="sm" />
                        <Button type="submit" color="green">
                          Submit
                        </Button>
                        <Space h="sm" />
                        <Text size="xs">
                          By clicking "submit," you acknowledge EmojiClue may
                          use your contribution. In the case of matching
                          submissions, the earliest contributor will be
                          credited.
                        </Text>
                      </form>
                    </Accordion.Panel>
                  </Accordion.Item>
                  {/* <Accordion.Item value="app">
                    <Accordion.Control>📱 Get the App</Accordion.Control>
                    <Accordion.Panel>
                      <Text size="sm">
                        Get the app and never miss another EmojiClue! Ok, it's
                        not <i>really</i> an app, but it looks and feels like
                        one. Here's how to get it in 7 seconds:
                        <Space h="sm" />
                        <List size="sm">
                          <Image
                            fit="contain"
                            alt="how-to"
                            src="get-the-app.gif"
                            withPlaceholder
                            styles={{
                              image: {
                                maxHeight: "500px",
                              },
                            }}
                          />
                          <Space h="sm" />
                          <List.Item>
                            <b>iPhone:</b> Tap the <i>Share</i> button in{" "}
                            <b>Safari</b>, then tap <i>Add to Home Screen</i>.
                          </List.Item>
                          <List.Item>
                            <b>Android:</b> Tap the <i>Share</i> button in{" "}
                            <b>Chrome</b>, then tap <i>Add to Home Screen</i>.
                            <br></br>
                          </List.Item>
                        </List>
                      </Text>
                    </Accordion.Panel>
                  </Accordion.Item> */}
                  {/* <Accordion.Item value="subscribe">
                    <Accordion.Control>
                      💌 Daily Reminders{" "}
                      <Badge color="yellow">Coming Soon</Badge>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Text size="sm">
                        Keep your 🔥<b>{winStreak > 0 && winStreak}</b> win
                        streak alive and never miss another clue with daily
                        reminders.{" "}
                        <i>
                          This feature is currently in development but you can
                          sign up now!
                        </i>
                      </Text>
                      <form
                        onSubmit={
                          subscribeChannelValue === "email"
                            ? dailyEmailSubscribeForm.onSubmit((values) => {
                                handleDailyEmailSubscribe();
                              })
                            : dailySmsSubscribeForm.onSubmit((values) => {
                                handleDailySmsSubscribe();
                              })
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <SegmentedControl
                            subscribeChannelValue={subscribeChannelValue}
                            onChange={setSubscribeChannelValue}
                            data={[
                              { label: "SMS", value: "phone_number" },
                              { label: "Email", value: "email" },
                            ]}
                            color="green"
                            size="xs"
                            radius="sm"
                            style={{ margin: "0px 0px 4px 0px" }}
                          />
                          {subscribeChannelValue === "email" ? (
                            <TextInput
                              size="md"
                              placeholder="jenny@gmail.com"
                              {...dailyEmailSubscribeForm.getInputProps(
                                "email"
                              )}
                            />
                          ) : (
                            <Input.Wrapper>
                              <TextInput
                                component={InputMask}
                                mask="(999) 999-9999"
                                size="md"
                                placeholder="(601) 867-5309"
                                {...dailySmsSubscribeForm.getInputProps(
                                  "phone_number"
                                )}
                              />
                            </Input.Wrapper>
                          )}
                        </div>
                        <Group position="right" mt="xs">
                          <Button type="submit" style={{ fontSize: "1.1em" }}>
                            👍
                          </Button>
                        </Group>
                      </form>
                    </Accordion.Panel>
                  </Accordion.Item> */}
                  {/* <Accordion.Item value="community">
                    <Accordion.Control>💬 Community</Accordion.Control>
                    <Accordion.Panel>
                      <Text size="sm">
                        Join the discussion and share your scores in the
                        EmojiClue Facebook group!
                      </Text>
                      <Space h="sm" />
                      <FacebookButton />
                    </Accordion.Panel>
                  </Accordion.Item> */}
                  <Accordion.Item value="support">
                    <Accordion.Control>💛 Support</Accordion.Control>
                    <Accordion.Panel>
                      <Text size="sm">
                        Do you get a little joy out of EmojiClue? Do you
                        consider yourself a true fan? Show your support for
                        EmojiClue by giving a helping hand below.
                      </Text>
                      <Space h="sm" />
                      <Popover position="top" withArrow shadow="md">
                        <Popover.Target>
                          <div
                            style={{
                              margin: "4px 0px 4px 0px",
                              width: "200px",
                            }}
                          >
                            <Button
                              fullWidth
                              onClick={() => {
                                setHighFiveCount(highFiveCount + 1);
                              }}
                            >
                              ✋Give a high five
                            </Button>
                            {highFiveCount % 100 === 0 && <HighFiveBurst />}
                          </div>
                        </Popover.Target>
                        <Popover.Dropdown>
                          <Text size="sm">✋Very nice!</Text>
                        </Popover.Dropdown>
                      </Popover>
                      <div style={{ margin: "4px" }}></div>
                      <Code>Total High Fives:</Code>
                      <Code color="blue">{highFiveCount}</Code>
                    </Accordion.Panel>
                  </Accordion.Item>
                  <Accordion.Item value="about">
                    <Accordion.Control>ℹ️ About</Accordion.Control>
                    <Accordion.Panel>
                      <Text size="sm">
                        EmojiClue is based on the <RebusPopover />, an
                        iconography-based puzzle and form of heraldic expression
                        that can be traced back to the Middle Ages. Ancient
                        Egyptian hieroglyphics, one of the the earliest known
                        forms of writing, also employed elements of the rebus.
                        5,000 years later, it's still a fun way to test your
                        knowledge of movies, books, songs, and more trivia.
                      </Text>

                      <div style={{ margin: "4px" }}></div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </Navbar.Section>
              <Navbar.Section>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    alignContent: "flex-end",
                    margin: "0px 0px 5px 0px",
                  }}
                >
                  <Text size="xs" color="dimmed">
                    <p style={{ margin: "4px 0px 8px 5px" }}>
                      Built with ❤️ by Aubrey
                    </p>
                    <p>
                      &nbsp;© {currentYear} Peninsula Projects, LLC. View{" "}
                      <a
                        href="#"
                        onClick={() => setPrivacyPolicyModalOpened(true)}
                      >
                        Privacy Policy
                      </a>
                    </p>
                  </Text>
                  <Modal
                    opened={privacyPolicyModalOpened}
                    onClose={() => {
                      setPrivacyPolicyModalOpened(false);
                    }}
                    closeButtonLabel
                    closeOnClickOutside
                    closeOnEscape
                    overlayOpacity={0}
                    padding="xs"
                  >
                    <PrivacyPolicy />
                  </Modal>
                </div>
              </Navbar.Section>
            </Navbar>
          }
          header={
            <Header height={60} p="md" className="App-Shell-Header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  height: "100%",
                }}
              >
                <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                  <Popover
                    width={200}
                    position="bottom"
                    withArrow
                    transition="fade"
                    shadow="md"
                    opened={menuNoticeOpened}
                  >
                    <Popover.Target>
                      <Burger
                        opened={navbarOpened}
                        onClick={() => {
                          setNavbarOpened((o) => !o);
                          !isComplete && guessRef.current.blur();
                        }}
                        size="sm"
                        color="white"
                        mr="xl"
                      />
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Text size="sm" style={{ color: "black" }}>
                        See how to play and more!
                      </Text>
                    </Popover.Dropdown>
                  </Popover>
                </MediaQuery>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    //Conditionally add padding of 29px for isTouch and 329px for not
                    padding: "0px 40px 0px 0px",
                    flex: "1",
                  }}
                >
                  <Text
                    onClick={() => {
                      setHeaderEmojiClickCount(
                        (headerEmojiClickCount + 1) % headerEmojiArray.length
                      );
                      setHeaderEmoji(headerEmojiArray[headerEmojiClickCount]);
                    }}
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        padding: "6px 0px 0px 0px",
                      }}
                    >
                      <span style={{ fontSize: "32px" }}>{headerEmoji}</span>
                      <span style={{ fontSize: "36px", fontWeight: "700" }}>
                        EmojiClue
                      </span>
                      <span style={{ fontSize: "32px" }}>🧩</span>
                    </div>
                  </Text>
                </div>
              </div>
            </Header>
          }
        >
          <div className="body">
            <Modal
              fullScreen
              opened={archiveChallengeOpened}
              onClose={() => {
                setArchiveChallengeOpened(false);
                setVisible(false);
              }}
              closeOnClickOutside={false}
              styles={{
                modal: {
                  padding: "20px 16px 20px 16px !important",
                },
              }}
            >
              <Header height={60} p="md" className="App-Shell-Header">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: "100%",
                  }}
                >
                  <Button
                    p="xs"
                    color="red"
                    onClick={() => {
                      resetArchiveGame();
                      setArchiveChallengeOpened(false);
                      setVisible(false);
                    }}
                  >
                    Back
                  </Button>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "0px 40px 0px 0px",
                      flex: "1",
                    }}
                  >
                    <Text
                      onClick={() => {
                        setHeaderEmojiClickCount(
                          (headerEmojiClickCount + 1) % headerEmojiArray.length
                        );
                        setHeaderEmoji(headerEmojiArray[headerEmojiClickCount]);
                      }}
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          padding: "6px 0px 0px 0px",
                        }}
                      >
                        <span style={{ fontSize: "32px" }}>{headerEmoji}</span>
                        <span
                          style={{
                            fontSize: "36px",
                            fontWeight: "700",
                          }}
                        >
                          EmojiClue
                        </span>
                        <span style={{ fontSize: "32px" }}>🧩</span>

                        <div>
                          <span
                            style={{
                              fontSize: ".5em",
                            }}
                          >
                            <i> Archive</i>
                          </span>
                        </div>
                      </div>
                    </Text>
                  </div>
                </div>
              </Header>
              <div className="body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <Text>
                      Guesses: {iconByNumber(archiveGuessesRemainingCount)}
                      &nbsp; |&nbsp; Hints:{" "}
                      {iconByNumber(2 - archiveHintRevealedCount)}
                      &nbsp;
                      {!archiveIsComplete &&
                        `| ${"  "} Points Possible: ${iconByNumber(
                          archivePointsPossible
                        )}`}
                    </Text>
                  </div>
                  <div style={{ minHeight: "1.5em" }}>
                    {archiveIsComplete && (
                      <>
                        <div className="centered">
                          <ArchiveCompleteModal
                            isCorrect={archiveIsCorrect}
                            winScore={archiveWinScore}
                            loseScore={loseScore}
                          />
                        </div>
                        <YourScore
                          isCorrect={archiveIsCorrect}
                          winScore={archiveWinScore}
                          loseScore={loseScore}
                        />
                      </>
                    )}
                  </div>{" "}
                  <div style={{ height: "2.7em" }}>
                    {archivePrevGuesses.map((archiveGuess, index) => (
                      <Text
                        key={index}
                        align="center"
                        size="sm"
                        strikethrough
                        color="dimmed"
                        style={{
                          fontSize: ".9em",
                        }}
                      >
                        {archiveGuess}
                      </Text>
                    ))}
                  </div>
                  <br />
                  <>
                    {archiveChallenge.prompt === undefined ? (
                      <>
                        <div></div>
                        <Space h="xl" />
                        <div className="gamePrompt">
                          <Loader variant="dots" size="lg" />
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <Badge color="violet" size="lg">
                            {emojiByCategory(archiveChallenge.category)}{" "}
                            {archiveChallenge.category}
                          </Badge>
                        </div>
                        <div className="gamePrompt">
                          <Prompt text={archiveChallenge.prompt} />
                        </div>
                      </>
                    )}
                  </>
                  <br />
                </div>
                <>
                  <ArchiveHintModal
                    hint1={archiveChallenge.hint1}
                    hint2={archiveChallenge.hint2}
                    hint1ModalOpened={archiveHint1ModalOpened}
                    hint2ModalOpened={archiveHint2ModalOpened}
                    setHint1ModalOpened={setArchiveHint1ModalOpened}
                    setHint2ModalOpened={setArchiveHint2ModalOpened}
                  />
                </>
                <>
                  <div className="hintTray">
                    <Button
                      onClick={(e) => {
                        if (!archiveHint1Shown) {
                          countArchiveHintReveals();
                          setArchiveHint1ModalOpened(true);
                          setArchiveHint1Shown(true);
                          return;
                        } else {
                          setArchiveHint1ModalOpened(true);
                        }
                      }}
                      uppercase
                      className="hintKey"
                      style={{ margin: "0px 2px 0px 0px" }}
                    >
                      🎁 Hint #1
                    </Button>
                    <Button
                      onClick={(e) => {
                        if (!archiveHint2Shown) {
                          countArchiveHintReveals();
                          setArchiveHint2ModalOpened(true);
                          setArchiveHint2Shown(true);
                          return;
                        } else {
                          setArchiveHint2ModalOpened(true);
                        }
                      }}
                      uppercase
                      className="hintKey"
                      style={{ margin: "0px 0px 0px 2px" }}
                    >
                      🎁 Hint #2&nbsp;
                    </Button>
                  </div>

                  <div className="hintTray" style={{ minHeight: "50px" }}>
                    <TextInput
                      id="archiveGuess"
                      ref={archiveGuessRef}
                      autoFocus
                      autoCapitalize="words"
                      type="text"
                      radius="sm"
                      disabled={archiveIsComplete}
                      style={{
                        maxWidth: "452px",
                      }}
                      styles={{
                        root: {
                          fontSize: "18px",
                          flexGrow: "1 !important",
                        },
                        input: {
                          fontSize: "18px",
                          height: "20px",
                          lineHeight: "20px",
                        },
                      }}
                      value={archiveGuess}
                      onChange={(e) => {
                        archiveGuessLettersArray.push(
                          e.target.value.charAt(e.target.value.length - 1)
                        );
                        setArchiveGuess(e.target.value);
                      }}
                      onKeyPress={(e) => archiveKeyDownHandler(e)}
                      // onFocus={() =>
                      //   focusArchiveGuessAfterIncorrect()
                      // }
                    />
                    <Button
                      color="green"
                      onClick={() => handleArchiveGuessSubmit()}
                      style={{ margin: "0px 0px 0px 3px" }}
                    >
                      <Text color="white">✓</Text>
                    </Button>
                  </div>
                </>
                {isTouch ? (
                  <div></div>
                ) : (
                  <div className="keyboard">
                    <div className="row">
                      <button
                        className="key"
                        data-key="Q"
                        value="Q"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        Q
                      </button>
                      <button
                        className="key"
                        data-key="W"
                        value="W"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        W
                      </button>
                      <button
                        className="key"
                        data-key="E"
                        value="E"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        E
                      </button>
                      <button
                        className="key"
                        data-key="R"
                        value="R"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        R
                      </button>
                      <button
                        className="key"
                        data-key="T"
                        value="T"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        T
                      </button>
                      <button
                        className="key"
                        data-key="Y"
                        value="Y"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        Y
                      </button>
                      <button
                        className="key"
                        data-key="U"
                        value="U"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        U
                      </button>
                      <button
                        className="key"
                        data-key="I"
                        value="I"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        I
                      </button>
                      <button
                        className="key"
                        data-key="O"
                        value="O"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        O
                      </button>
                      <button
                        className="key"
                        data-key="P"
                        value="P"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        P
                      </button>
                    </div>
                    <div className="row">
                      <div className="spacer"></div>
                      <button
                        className="key"
                        data-key="A"
                        value="A"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        A
                      </button>
                      <button
                        className="key"
                        data-key="S"
                        value="S"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        S
                      </button>
                      <button
                        className="key"
                        data-key="D"
                        value="D"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        D
                      </button>
                      <button
                        className="key"
                        data-key="F"
                        value="F"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        F
                      </button>
                      <button
                        className="key"
                        data-key="G"
                        value="G"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        G
                      </button>
                      <button
                        className="key"
                        data-key="H"
                        value="H"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        H
                      </button>
                      <button
                        className="key"
                        data-key="J"
                        value="J"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        J
                      </button>
                      <button
                        className="key"
                        data-key="K"
                        value="K"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        K
                      </button>
                      <button
                        className="key"
                        data-key="L"
                        value="L"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        L
                      </button>
                      <div className="spacer"></div>
                    </div>
                    <div className="row">
                      <div className="spacer"></div>
                      <div className="spacer"></div>
                      <div className="spacer"></div>
                      <div className="spacer"></div>
                      <button
                        className="key"
                        data-key="Z"
                        value="Z"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        Z
                      </button>
                      <button
                        className="key"
                        data-key="X"
                        value="X"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        X
                      </button>
                      <button
                        className="key"
                        data-key="C"
                        value="C"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        C
                      </button>
                      <button
                        className="key"
                        data-key="V"
                        value="V"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        V
                      </button>
                      <button
                        className="key"
                        data-key="B"
                        value="B"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        B
                      </button>
                      <button
                        className="key"
                        data-key="N"
                        value="N"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        N
                      </button>
                      <button
                        className="key"
                        data-key="M"
                        value="M"
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        M
                      </button>
                      <button
                        data-delete
                        className="key large"
                        onMouseDown={handleArchiveBackspace}
                      >
                        &nbsp;&nbsp;
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path
                            fill="var(--color-tone-1)"
                            d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7.07L2.4 12l4.66-7H22v14zm-11.59-2L14 13.41 17.59 17 19 15.59 15.41 12 19 8.41 17.59 7 14 10.59 10.41 7 9 8.41 12.59 12 9 15.59z"
                          ></path>
                        </svg>
                        &nbsp;&nbsp;&nbsp;
                      </button>
                    </div>
                    <div className="row">
                      <button
                        className="key clear"
                        onMouseDown={handleArchiveClear}
                        style={{
                          backgroundColor: "#F03E3E",
                          color: "white",
                        }}
                      >
                        CLEAR
                      </button>
                      <button
                        data-space
                        className="key space"
                        value=" "
                        onMouseDown={handleArchiveClick}
                        onTouchStart={handleArchiveTouchStart}
                      >
                        SPACE
                      </button>
                      <button
                        data-enter
                        className="key large"
                        onMouseDown={handleArchiveGuessSubmit}
                        style={{
                          backgroundColor: "#37B24D",
                          color: "white",
                        }}
                      >
                        Enter
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Modal>
            <Modal
              overlayOpacity={0.55}
              overlayBlur={1}
              centered
              opened={welcomeModalOpened}
              onClose={() => {
                setWelcomeModalOpened(false);
                focusGuess();
                isTouch && setMenuNoticeOpened(true);
                setTimeout(() => {
                  setMenuNoticeOpened(false);
                }, 5000);
              }}
              title={<b>👋 Join the Waitlist!</b>}
              style={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                alignSelf: "center",
                justifySelf: "center",
              }}
            >
              <Box sx={{ maxWidth: 300 }} mx="auto">
                <Text>
                  Enter your email to receive the daily challenges once the game
                  is live!
                </Text>
                <br></br>
                <form
                  onSubmit={waitlistForm.onSubmit((values) => {
                    handleWaitlistSubmit();
                    focusGuess();
                    setTimeout(() => {
                      setMenuNoticeOpened(true);
                    }, 3000);
                    setTimeout(() => {
                      setMenuNoticeOpened(false);
                    }, 4000);
                  })}
                >
                  <TextInput
                    withAsterisk
                    autoCapitalize="off"
                    {...waitlistForm.getInputProps("email")}
                    size="md"
                    label="Email"
                    placeholder="your@email.com"
                  />
                  <Group position="right" mt="md">
                    <Button type="submit" style={{ fontSize: "1em" }}>
                      🤝
                    </Button>
                  </Group>
                </form>
              </Box>
            </Modal>
            <HowToPlay
              howToPlayModalOpened={howToPlayModalOpened}
              setHowToPlayModalOpened={setHowToPlayModalOpened}
              focusGuessAfterIncorrect={focusGuessAfterIncorrect}
              trackEvent={trackEvent}
            />
            <Modal
              opened={howToHint1ModalOpened}
              title={<b>Use a hint?</b>}
              withCloseButton={false}
              size="sm"
            >
              <Text size="md">Each hint costs 1 point</Text>
              <br></br>
              <Checkbox defaultChecked label="Don't remind me" size="xs" />
              <br></br>
              <Group position="right" spacing="sm">
                <Button
                  color="red"
                  onClick={() => {
                    setHowToHint1ModalOpened(false);
                    setIsFirstHint(false);
                  }}
                >
                  Abort{" "}
                </Button>
                <Button
                  color="green"
                  onClick={() => {
                    setHowToHint1ModalOpened(false);
                    setHint1ModalOpened(true);
                    setIsFirstHint(false);
                    setHint1Shown(true);
                    !isComplete && countHintReveals();
                  }}
                >
                  Continue
                </Button>
              </Group>
            </Modal>
            <Modal
              opened={howToHint2ModalOpened}
              title={<b>Use a hint?</b>}
              withCloseButton={false}
              size="sm"
            >
              <Text size="md">Each hint costs 1 point.</Text>
              <br></br>
              <Checkbox defaultChecked label="Don't remind me" size="xs" />
              <br></br>
              <Group position="right" spacing="sm">
                <Button
                  color="red"
                  onClick={() => {
                    setHowToHint2ModalOpened(false);
                    setIsFirstHint(false);
                  }}
                >
                  Abort{" "}
                </Button>
                <Button
                  color="green"
                  onClick={() => {
                    setHowToHint2ModalOpened(false);
                    setHint2ModalOpened(true);
                    setIsFirstHint(false);
                    setHint2Shown(true);
                    !isComplete && countHintReveals();
                  }}
                >
                  Continue
                </Button>
              </Group>
            </Modal>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div>
                <Text>
                  Guesses: {iconByNumber(guessesRemainingCount)}&nbsp; |&nbsp;
                  Hints: {iconByNumber(2 - hintRevealedCount)}&nbsp;
                  {!isComplete &&
                    `| ${"  "} Points Possible: ${iconByNumber(
                      pointsPossible
                    )}`}
                </Text>
              </div>
              <div style={{ minHeight: "1.5em" }}>
                {isComplete && (
                  <>
                    <div className="centered">
                      <CompleteModal
                        winScore={winScore}
                        loseScore={loseScore}
                      />
                    </div>
                    <Text weight={600}>
                      Your Score: {isCorrect ? winScore : loseScore}
                    </Text>
                  </>
                )}
              </div>{" "}
              {/* {!isComplete && (
                <Container>
                  <Button
                    size="sm"
                    color="red"
                    styles={{
                      root: {
                        height: "20px",
                      },
                    }}
                    onClick={() => submitForfeit()}
                  >
                    Show me the answer 🏳️
                  </Button>
                </Container>
              )} */}
              <div style={{ height: "2.7em" }}>
                {prevGuesses.map((guess, index) => (
                  <Text
                    key={index}
                    align="center"
                    size="sm"
                    strikethrough
                    color="dimmed"
                    style={{
                      fontSize: ".9em",
                    }}
                  >
                    {guess}
                  </Text>
                ))}
              </div>
              <br />
              <>
                {prompt === undefined ? (
                  <>
                    <div></div>
                    <Space h="xl" />
                    <div className="gamePrompt">
                      <Loader variant="dots" size="lg" />
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <Badge color="violet" size="lg">
                        {emojiByCategory(category)} {category}
                      </Badge>
                    </div>
                    <div className="gamePrompt">
                      <Prompt text={prompt} />
                    </div>
                  </>
                )}
              </>
              <br />
            </div>
            <>
              <HintModal
                hint1={hint1}
                hint2={hint2}
                hint1ModalOpened={hint1ModalOpened}
                hint2ModalOpened={hint2ModalOpened}
                setHint1ModalOpened={setHint1ModalOpened}
                setHint2ModalOpened={setHint2ModalOpened}
                // guessRef={guessRef}
              />
            </>
            {!isComplete && (
              <>
                <div className="hintTray">
                  <Button
                    onClick={(e) => {
                      if (isFirstHint) {
                        setHowToHint1ModalOpened(true);
                        return;
                      }
                      if (!isFirstHint && !hint1Shown && !isComplete) {
                        countHintReveals();
                        setHint1ModalOpened(true);
                        setHint1Shown(true);
                        return;
                      } else {
                        setHint1ModalOpened(true);
                      }
                    }}
                    onClose={() => setHint1Shown(true)}
                    uppercase
                    className="hintKey"
                    style={{ margin: "0px 2px 0px 0px" }}
                  >
                    🎁 Hint #1
                  </Button>
                  <Button
                    onClick={(e) => {
                      if (isFirstHint) {
                        setHowToHint2ModalOpened(true);
                        return;
                      }
                      if (!isFirstHint && !hint2Shown && !isComplete) {
                        countHintReveals();
                        setHint2ModalOpened(true);
                        setHint2Shown(true);
                        return;
                      } else {
                        setHint2ModalOpened(true);
                      }
                    }}
                    uppercase
                    className="hintKey"
                    style={{ margin: "0px 0px 0px 2px" }}
                  >
                    🎁 Hint #2&nbsp;
                  </Button>
                </div>

                <div className="hintTray" style={{ minHeight: "50px" }}>
                  <TextInput
                    id="guess"
                    ref={guessRef}
                    autoFocus
                    autoCapitalize="words"
                    type="text"
                    radius="sm"
                    disabled={isComplete}
                    style={{
                      maxWidth: "452px",
                    }}
                    styles={{
                      root: {
                        fontSize: "18px",
                        flexGrow: "1 !important",
                      },
                      input: {
                        fontSize: "18px",
                        height: "20px",
                        lineHeight: "20px",
                      },
                    }}
                    value={guess}
                    onChange={(e) => {
                      guessLettersArray.push(
                        e.target.value.charAt(e.target.value.length - 1)
                      );
                      setGuess(e.target.value);
                    }}
                    onKeyPress={(e) => keyDownHandler(e)}
                    // onFocus={() => focusGuessAfterIncorrect()}
                  />
                  <Button
                    color="green"
                    onClick={() => handleGuessSubmit()}
                    style={{ margin: "0px 0px 0px 3px" }}
                  >
                    <Text color="white">✓</Text>
                  </Button>
                </div>
              </>
            )}
            {!isComplete ? (
              isTouch ? (
                <div></div>
              ) : (
                <div className="keyboard">
                  <div className="row">
                    <button
                      className="key"
                      data-key="Q"
                      value="Q"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      Q
                    </button>
                    <button
                      className="key"
                      data-key="W"
                      value="W"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      W
                    </button>
                    <button
                      className="key"
                      data-key="E"
                      value="E"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      E
                    </button>
                    <button
                      className="key"
                      data-key="R"
                      value="R"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      R
                    </button>
                    <button
                      className="key"
                      data-key="T"
                      value="T"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      T
                    </button>
                    <button
                      className="key"
                      data-key="Y"
                      value="Y"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      Y
                    </button>
                    <button
                      className="key"
                      data-key="U"
                      value="U"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      U
                    </button>
                    <button
                      className="key"
                      data-key="I"
                      value="I"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      I
                    </button>
                    <button
                      className="key"
                      data-key="O"
                      value="O"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      O
                    </button>
                    <button
                      className="key"
                      data-key="P"
                      value="P"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      P
                    </button>
                  </div>
                  <div className="row">
                    <div className="spacer"></div>
                    <button
                      className="key"
                      data-key="A"
                      value="A"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      A
                    </button>
                    <button
                      className="key"
                      data-key="S"
                      value="S"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      S
                    </button>
                    <button
                      className="key"
                      data-key="D"
                      value="D"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      D
                    </button>
                    <button
                      className="key"
                      data-key="F"
                      value="F"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      F
                    </button>
                    <button
                      className="key"
                      data-key="G"
                      value="G"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      G
                    </button>
                    <button
                      className="key"
                      data-key="H"
                      value="H"
                      onMouseDown={() => handleClick()}
                      onTouchStart={handleTouchStart}
                    >
                      H
                    </button>
                    <button
                      className="key"
                      data-key="J"
                      value="J"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      J
                    </button>
                    <button
                      className="key"
                      data-key="K"
                      value="K"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      K
                    </button>
                    <button
                      className="key"
                      data-key="L"
                      value="L"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      L
                    </button>
                    <div className="spacer"></div>
                  </div>
                  <div className="row">
                    <div className="spacer"></div>
                    <div className="spacer"></div>
                    <div className="spacer"></div>
                    <div className="spacer"></div>
                    <button
                      className="key"
                      data-key="Z"
                      value="Z"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      Z
                    </button>
                    <button
                      className="key"
                      data-key="X"
                      value="X"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      X
                    </button>
                    <button
                      className="key"
                      data-key="C"
                      value="C"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      C
                    </button>
                    <button
                      className="key"
                      data-key="V"
                      value="V"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      V
                    </button>
                    <button
                      className="key"
                      data-key="B"
                      value="B"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      B
                    </button>
                    <button
                      className="key"
                      data-key="N"
                      value="N"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      N
                    </button>
                    <button
                      className="key"
                      data-key="M"
                      value="M"
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      M
                    </button>
                    <button
                      data-delete
                      className="key large"
                      onMouseDown={handleBackspace}
                    >
                      &nbsp;&nbsp;
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path
                          fill="var(--color-tone-1)"
                          d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7.07L2.4 12l4.66-7H22v14zm-11.59-2L14 13.41 17.59 17 19 15.59 15.41 12 19 8.41 17.59 7 14 10.59 10.41 7 9 8.41 12.59 12 9 15.59z"
                        ></path>
                      </svg>
                      &nbsp;&nbsp;&nbsp;
                    </button>
                  </div>
                  <div className="row">
                    <button
                      className="key clear"
                      onMouseDown={handleClear}
                      style={{ backgroundColor: "#F03E3E", color: "white" }}
                    >
                      CLEAR
                    </button>
                    <button
                      data-space
                      className="key space"
                      value=" "
                      onMouseDown={handleClick}
                      onTouchStart={handleTouchStart}
                    >
                      SPACE
                    </button>
                    <button
                      data-enter
                      className="key large"
                      onMouseDown={handleGuessSubmit}
                      style={{ backgroundColor: "#37B24D", color: "white" }}
                    >
                      Enter
                    </button>
                  </div>
                </div>
              )
            ) : (
              <Container>
                <Space h="sm" />
                <Text color="green" weight={600}>
                  {officialAnswer}
                </Text>
                <div className="heightSpacer"></div>
                {/* <CountDown />
                <div className="heightSpacer"></div> */}
                <Text
                  size="sm"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "4px",
                    minWidth: "250px",
                  }}
                ></Text>
                <Button
                  color="blue"
                  variant="outline"
                  fullWidth
                  mt="md"
                  style={{ width: "100%", margin: "2px", fontSize: "16px" }}
                  onClick={() => {
                    setCompleteModalOpened(false);
                    setNavbarOpened(true);
                    setAccordionValueOpen("archive");
                    getArchive();
                  }}
                >
                  Play prior challenges 🏋️
                </Button>
                <Button
                  color="green"
                  onClick={() => {
                    trackEvent("Share");
                    isTouch ? onShare() : copyToClipboard();
                  }}
                  fullWidth
                  mt="md"
                  style={{ width: "100%", margin: "2px", fontSize: "16px" }}
                >
                  Share your score!{" "}
                  {isCorrect ? emojiByScore(userScore) : emojiByScore(0)}
                </Button>
              </Container>
            )}
          </div>
        </AppShell>
      </NotificationsProvider>
    </div>
  );
}
