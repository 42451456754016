import React, { useEffect } from "react";
import { useReward } from "react-rewards";

export default function ConfettiBurst() {
  const { reward: confettiReward, isAnimating: isConfettiAnimating } =
    useReward("confettiReward", "confetti", {
      elementCount: 70,
    });

  useEffect(() => {
    confettiReward();
  }, []);

  return (
    <div className="centered">
      <span id="confettiReward" />
    </div>
  );
}
