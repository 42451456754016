import React from "react";
import { Card, Container, Group, Popover, Image, Text } from "@mantine/core";

export default function RebusPopover() {
  return (
    <Popover zIndex={1999999999999999} position="top">
      <Popover.Target>
        <span>
          <u>Rebus</u>
        </span>
      </Popover.Target>
      <Popover.Dropdown zIndex={1999999999999999}>
        <Card p="sm" radius="md" style={{ zIndex: "99999999", width: "250px" }}>
          <Card.Section>
            <Container
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Image
                src="https://upload.wikimedia.org/wikipedia/commons/0/00/Rebus_escort_card.jpg"
                width={280}
                alt="Rebus example"
              />
            </Container>
          </Card.Section>
          <Group position="apart">
            <Group spacing="xs" mt="xs" mb="xs">
              <Text size="xs" italic>
                A rebus (/ˈriːbəs/) is a puzzle device that combines the use of
                illustrated pictures with individual letters to depict words or
                phrases. For example: the word "been" might be depicted by a
                rebus showing an illustrated bumblebee next to a plus sign (+)
                and the letter "n". It was a favorite form of heraldic
                expression used in the Middle Ages to denote surnames.
              </Text>
            </Group>
          </Group>
        </Card>
      </Popover.Dropdown>
    </Popover>
  );
}
