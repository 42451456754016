import React from "react";
import { Button, Group, Modal, Space, Text } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
// import Autoplay from 'embla-carousel-autoplay';
// import HowToPlayText from "./HowToPlayText";

export default function HowToPlay({
  howToPlayModalOpened,
  setHowToPlayModalOpened,
  focusGuessAfterIncorrect,
  trackEvent,
}) {
  return (
    <>
      <Modal
        opened={howToPlayModalOpened}
        title={<b>🎮 A quick warmup before you play!</b>}
        onClose={() => {
          setHowToPlayModalOpened(false);
          focusGuessAfterIncorrect();
          trackEvent("Enter Game");
        }}
      >
        <Carousel
          sx={{ maxWidth: 320 }}
          slideSize="75%"
          mx="auto"
          slideGap="xl"
          withIndicators
          loop
          // height="100%"
          styles={{
            indicator: {
              backgroundColor: "#228BE6",
            },
            control: {
              backgroundColor: "#228BE6",
              color: "#FFFFFF",
            },
          }}
        >
          <Carousel.Slide>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                padding: "15px",
                backgroundColor: "#F8F9FA",
              }}
            >
              <Text>Sound out the emojis!</Text>
              <br></br>
              <span style={{ fontSize: "2em" }}>🌎📅</span>
              <br></br>
              <Text>Earth - Day</Text>
              <br></br>
              <br></br>
            </div>
          </Carousel.Slide>
          <Carousel.Slide>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                padding: "15px",
                backgroundColor: "#F8F9FA",
              }}
            >
              <Text>Sound out the emojis!</Text>
              <br></br>
              <span style={{ fontSize: "2em" }}>🅱️🌎📅</span> <br></br>
              <Text>
                B-<i>irth</i>-day
              </Text>
              <br></br>
              <br></br>
            </div>
          </Carousel.Slide>
          <Carousel.Slide>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                padding: "15px",
                backgroundColor: "#F8F9FA",
              }}
            >
              <Text>Sound out the emojis!</Text>
              <br></br>
              <span style={{ fontSize: "2em" }}>🅱️🌎📅⛳🫖</span> <br></br>
              <Text>
                B-<i>irth</i>-day-par-<i>ty</i>
              </Text>
              <br></br>
              <br></br>
            </div>
          </Carousel.Slide>
          <Carousel.Slide>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                padding: "15px",
                backgroundColor: "#F8F9FA",
              }}
            >
              <Text>You're ready! 😎</Text>
              <br></br>
              <Text>
                Just remember: emojis can have many different meanings!
              </Text>
              <Space h="xs" />
              <br></br>
              <br></br>
              <br></br>
            </div>
          </Carousel.Slide>
        </Carousel>
        <div style={{ height: "10px" }}></div>
        <Group position="right" spacing="md" style={{ paddingBottom: "8px" }}>
          <Button
            color="green"
            onClick={() => {
              setHowToPlayModalOpened(false);
              focusGuessAfterIncorrect();
              trackEvent("Enter Game");
            }}
          >
            Play!
          </Button>
        </Group>
        <Text color="dimmed" size="xs">
          <u>Tip</u>: Users who sound out guesses <u>aloud</u> score 27% higher!
        </Text>
      </Modal>
    </>
  );
}
