export default function iconByNumber(number) {
  const numberIcon = {
    5: "5️⃣",
    4: "4️⃣",
    3: "3️⃣",
    2: "2️⃣",
    1: "1️⃣",
    0: "0️⃣",
  };
  return numberIcon[number];
}
