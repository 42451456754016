export default function messageByScore(score) {
  const scoreMessage = {
    5: "You are a genius! 😎",
    4: "Excellent! 🙌",
    3: "Nicely done! 👏",
    2: "Good job! 👍",
    1: "Hey, you did it! 🙂",
    0: "Better luck next time! 😢",
  };
  return scoreMessage[score];
}
